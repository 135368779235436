
import store from "@/store/index";
import { EmployeegroupController, RightController } from '@/controller';
import { Button, IconButton, Card, Checkbox /*, CheckboxMultipleSelect/*, FileUpload, SelectSingle, DateInput */} from '@/ui/index';
import { defineComponent } from 'vue';
import { Employeegroup, Right } from '@/model';

export default defineComponent({
    name: "EmployeeForm",
    components: { Button, IconButton, Card, Checkbox /*, CheckboxMultipleSelect, FileUpload, SelectSingle, DateInput */ },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                rights: [] as Array<any>,
            },
            employeegroup: {} as Employeegroup | null,
            rights: [] as Array<Right>
        }
    },
    computed:{
        displayRights(): any[] {
            const temp : Array<any> = []
            this.rights.forEach((item:Right) => {
               const entry = {
                'uid': item.uid as number,
                'title': item.title as string,
                'identifier': item.identifier as string,
                'isSet': this.form.rights.find((el:any) => el.uid == item.uid) != null
               } 
               temp.push(entry)
            });
            return temp
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.getEmployeegroup(this.$route.params.id)
        } else {
            this.editMode = false
        }
        this.getRights()
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSave() {
            this.handleSubmitPrevent()
        },
        handleCreate() {
            this.handleSubmitPrevent()
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.updateEmployeegroup();
            } else {
                this.createEmployeegroup();
            }
        },
        handleRightsChange(right:Right, value:boolean) {
            if (value) {
                const entry = {
                    'uid': right?.uid as number,
                    'identifier': right?.identifier as string,
                    'title': right?.title as string
                }
                this.form.rights.push(entry)
            } else {
                const idx = this.form.rights.findIndex((item:any) => item.uid == right.uid)
                if (idx != -1) this.form.rights.splice(idx, 1)
            }
        },
        setFormData(employeegroup: Employeegroup) {
            this.form.uid = employeegroup.getUid()
            this.form.title = employeegroup.getTitle()
            this.form.rights = employeegroup.getRightsArray()
            this.employeegroup = employeegroup
        },
        async getEmployeegroup(uid:any) {
            const res = await EmployeegroupController.getEmployeegroupById(uid);
            if (!res.error) {
                this.setFormData(res.employeegroup)
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getRights() {
            const res = await RightController.fetchRights();
            if (!res.error) {
                this.rights = res.items
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async createEmployeegroup() {
            const res = await EmployeegroupController.createEmployeegroup(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.employeegroupCreated"), { position: "top", type: "success" });
                this.$router.push("/administration/employeegroup/detail/" + res.employeegroup.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async updateEmployeegroup() {
            const res = await EmployeegroupController.updateEmployeegroup(this.form);
            if (!res.error) {
                this.setFormData(res.employeegroup);
                this.$notify(this.$t("success.employeegroupUpdated"), { position: "top", type: "success" });
                this.$router.push("/administration/employeegroup/detail/" + res.employeegroup.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
