import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "timi-employeegroup-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-form-item" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('employeegroup.generalData')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.employeegroupTitle')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.employeegroupTitle'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.title]
          ])
        ]),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('employeegroup.rights')), 1),
        _createVNode(_component_Card, {
          class: "timi-employeegroup-profile",
          contentLoading: !_ctx.employeegroup.uid
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayRights, (right) => {
              return (_openBlock(), _createBlock(_component_Checkbox, {
                value: right.isSet,
                key: right.uid,
                label: right.title,
                onOnChange: (value) => _ctx.handleRightsChange(right, value)
              }, null, 8, ["value", "label", "onOnChange"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["contentLoading"])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addEmployeegroup'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleCreate
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleSave
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}